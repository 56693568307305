import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'mobile-header',
  templateUrl: './mobile-header.component.html'
})
export class MobileHeaderComponent implements OnInit {

  @Output() onLanguageChange = new EventEmitter();
  @Output() onSectionSelected = new EventEmitter();

  @Input() sections;
  @Input() languages;

  isMenuOpened = false;
  allowAnimate;
  constructor() { }

  ngOnInit() {
     
  }

  ngAfterViewInit() {
    this.allowAnimate = true;
  }

  toggleSidebar() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  closeSidebar() {
    this.isMenuOpened = false;
  }

  onMenuItemClicked(item) {
    this.toggleSidebar();
    this.onSectionSelected.emit(item.id);
  }

  changeLanguage(newLang) {
    this.onLanguageChange.emit(newLang);
  }
  
}
