import { Component, ViewChild, ElementRef, Inject, PLATFORM_ID, HostListener } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Renderer2 } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AngularFireDatabase } from '@angular/fire/database';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'veganfest';



  sections;
  languages;
  languageChanging: boolean;

  scrolled: boolean;
  currentLanguage;
  defaultLanguage = "en";
  defaultDirection = "ltr";
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId, @Inject(WINDOW) private window: Window, private render: Renderer2, private translate: TranslateService, private elementRef: ElementRef, private db: AngularFireDatabase) {

  }

  initFacebookPixel(){
    try {
      if ((<any>this.window).fbq) {
        (<any>this.window).fbq('track', 'PageView');
      }
    }
    catch (error) {
    }
  }

  initLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.defaultLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.defaultLanguage);

    if (this.isBrowser) {
      this.render.addClass(document.body, this.defaultDirection);
    }

    this.currentLanguage = this.defaultLanguage;
  }



  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.languages = [
      {
        id: "en",
        direction: "ltr",
        label: "EN"
      }
    ];
   

    if (this.window.innerWidth < 992) {
      this.db.list('log/enterance/mobile').push(new Date().getTime());
      this.sections = [
        {
          label: "About",
          id: "about"
        },
        {
          label: "line Up",
          id: "lineUp"
        },
        {
          label: "Partner",
          id: "partner"
        },
        {
          label: "Our Giveaway",
          id: "signup"
        },
      ]
    } else {
      this.db.list('log/enterance/desktop').push(new Date().getTime());
      this.sections = [
        {
          label: "About",
          id: "about"
        },
        {
          label: "line Up",
          id: "lineUp"
        },
        {
          label: "Partner",
          id: "partner"
        },
        {
          label: "Our Giveaway",
          id: "signup"
        },
      ]
    }

    this.initLanguage();

    

    if (window.localStorage) {
      if (!window.localStorage.getItem("enter")){
        this.db.list('log/enterance/unique').push(new Date().getTime());
      }
      window.localStorage.setItem("enter", "true");
    }

    this.initFacebookPixel();
  
  }

  goToSignUp() {
    this.onSectionSelected('signup');
  }

  onSectionSelected(sectionId) {
    if (this.isBrowser) {
      let element = this.elementRef.nativeElement.querySelector('#' + sectionId);

      var topOfElement = element.offsetTop;
      if(this.window.innerWidth > 992) {
        topOfElement = topOfElement - 150;
      } else {
        topOfElement = topOfElement - 88;
      }
      //this.window.scroll({ top: topOfElement, behavior: "smooth" });


      var currentPosition = window.pageYOffset || document.documentElement.scrollTop;
      let isScrollToTop = topOfElement < currentPosition?  true : false;

      var i = currentPosition;
      var int = setInterval(function () {
        window.scrollTo(0, i);
        i = isScrollToTop? i-20 : i+20;

        if (isScrollToTop && i <= topOfElement) clearInterval(int);
        if (!isScrollToTop && i >= topOfElement) clearInterval(int);
      }, 2);

    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (this.isBrowser) {
      if (window.pageYOffset) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }

    }
  }

  setLanguage(lang) {

    if (lang.id == this.currentLanguage) {
      return;
    }

    if (lang.id == "en") {
      this.db.list('log/changeLanguageTo/en').push(new Date().getTime());
    } else {
      this.db.list('log/changeLanguageTo/he').push(new Date().getTime());
    }

    if (this.isBrowser) {
      this.languageChanging = true;

      setTimeout(_ => {
        this.currentLanguage = lang.id;
        this.render.removeClass(document.body, "rtl");
        this.render.removeClass(document.body, "ltr");

        this.render.addClass(document.body, lang.direction);
        this.translate.use(lang.id);
      }, 700);

      setTimeout(_ => {
        this.languageChanging = false;
      }, 800);


    }


  }


}
