import { Component, OnInit, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'map',
  templateUrl: './map.component.html'
})
export class MapComponent implements OnInit {

  

  lat: number = 50.831373034871014;
  lng: number = -0.136082122312254;
  zoom = 15;


  marker = {
    lat: this.lat,
    lng: this.lng,
    iconUrl: "./assets/images/marker-white.png?appVersion=20"
  }  

  directions = [
    {
      title: "address",
      icon: "./assets/images/address-icon.png?appVersion=20",
      details: "address details"
    },
    {
      title: "by bus",
      icon: "./assets/images/bus-icon.png?appVersion=20",
      details: "by bus details"
    },
    {
      title: "by train",
      icon: "./assets/images/train-icon.png?appVersion=20",
      details: "by train details"
    }
  ];

  constructor(@Inject(WINDOW) private window: Window) { }

  ngOnInit() {

  }

//50.831373034871014, -0.136082122312254

  openWaze() {
    window.open("https://www.waze.com/ul?ll=50.831373034871014%2C-0.136082122312254&navigate=yes&zoom=16");
  }

  openPopup(url) {
    let windowHeight = window.innerHeight;

    let screenWidth = screen.width;
    let windowWidth = window.innerWidth;

    let popupWidth = windowWidth - 100;
    let popupHeight = windowHeight - 100;
    let popupLeft = (screenWidth - popupWidth) / 2;

    this.window.open(url, "_blank", "scrollbars=yes,resizable=yes,top=50,left=" + popupLeft + ",width=" + popupWidth + ", height = " + popupHeight);
  }

  openFestivalMap() {
    this.openPopup("./assets/pdf/map.pdf?appVersion=20");
  }

}
