import { HostListener, Output, EventEmitter, Input, Component, OnInit, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {

  video;
  isPlay = true;
  isMute = true;
  playPauseIcon;
  videoBanner;
  muteIcon;
  displayVideoItems: boolean;
  displayVideoButtons;
  @Input() currentLanguage;
  @Output() onGoToSignupClicked = new EventEmitter();

  videoHeightMobile;

  videoSrc = "assets/images/vfMarketVideo.mp4";
  introImageSrc = "assets/images/introImage.jpg"
  userFullWatch;
  customFullScreen;

  constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId, private elementRef: ElementRef) { }


    ngOnInit() {
      this.setPlayPauseIcon();
      this.setMuteIcon();
      if (this.window.innerWidth < 992) {
        this.customFullScreen = true;
        this.videoHeightMobile = window.innerHeight - 88 - 0.18 * (0.5) * window.innerWidth + 'px';
      }

      this.customFullScreen = this.customFullScreen || this.isIe();
      
    }

    setVideoFullScreen(){
      
    }

    onVideoStartPlay() {
      this.displayVideoItems = true
    }

    goToSignupClicked() {
      this.onGoToSignupClicked.emit();
    }


    onVideoLoaded() {
      this.displayVideoButtons = true;
    }

    ngAfterViewInit(){
      if (isPlatformBrowser(this.platformId)) {
         this.video = this.elementRef.nativeElement.querySelector('#video');
      }

      this.muteAudio();
      this.playVideo();

    }


    private removeControls() {
      if (this.video) {
        this.video.removeAttribute("controls");
      }
    }

    private setShortVideo() {
      if (this.video) {
        this.videoSrc = "assets/video/shortVideo.mp4";

        this.video.pause();
        this.video.currentTime = 0;


        setTimeout(_ => {
          this.video.play();
          this.muteAudio();
        }, 100);
      }
    }

    videoClicked() {

      if (!this.video) {
        return;
      }

      if (!this.customFullScreen) {
          return;
      }
      if (this.userFullWatch) {
        this.userFullWatch = false;

        this.setShortVideo();
      }
    }


    private isIe() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!window.navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
      {
       return true;
      }

      return false;
    }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.userFullWatch){
      this.videoClicked();
    }
  }

    watchTheVideo() {
      if(this.video) {


        this.videoSrc = "assets/video/fullVideo.mp4?appVersion=20";
        this.video.pause();
        this.video.currentTime = 0;

        if (this.customFullScreen) {
          this.userFullWatch = true;
          if (window.history && window.history.pushState) {
            window.history.pushState(null, null, 'video');
          }
        
        } else {
          if (this.video.requestFullscreen) {
            this.video.requestFullscreen();
          } else if (this.video.mozRequestFullScreen) {
            this.video.mozRequestFullScreen();
          } else if (this.video.webkitRequestFullscreen) {
            this.video.webkitRequestFullscreen();
          } else if (this.video.msRequestFullscreen) {
            this.video.msRequestFullscreen();
          }
          
          this.video.setAttribute("controls", "controls");  
          document.addEventListener("fullscreenchange", _ => {
            let isFullScreenMode = (<any>document).fullScreen || (<any>document).mozFullScreen || (<any>document).webkitIsFullScreen || (1 >= (outerHeight - innerHeight) && this.isIe());

            if (!isFullScreenMode) {
              this.setShortVideo();
              this.removeControls();
            }

          });
          
        }
       
 
        setTimeout( _ => {
          this.playVideo();
          this.playAudio();
          
        }, 10);
       

      }
    }

    playVideo() {
      if (this.video) {
        this.isPlay = true;
        this.video.play();
        this.setPlayPauseIcon();
      }
       
    }

    onVideoEnd() {
      this.userFullWatch = false;
    }

    pauseVideo() {
      if (this.video){
         this.video.pause();
         this.isPlay = false;
        this.setPlayPauseIcon();
      }
        
    }

    playAudio() {
      if (this.video){
        this.isMute = false;
        this.video.muted = false;
        this.setMuteIcon();
      }

         
    }

    muteAudio() {
      if (this.video) {
        this.isMute = true;
        this.video.muted = true;
        this.setMuteIcon();
      }
    }

  toggleMute() {
    if (!this.isMute) {
      this.muteAudio();
    } else {
      this.playAudio()
    }

    

  }


    togglePlayPause() {
      if (!this.isPlay) {
        this.playVideo()
      } else {
        this.pauseVideo();
      }

     

    }

    setMuteIcon() {
      this.muteIcon = "assets/images/";
      this.muteIcon += this.isMute ? 'speak.png?appVersion=20' : 'mute.png?appVersion=20';
    }

    setPlayPauseIcon() {
      this.playPauseIcon = "assets/images/";
      this.playPauseIcon += this.isPlay ? 'pause.png?appVersion=20' : 'play.png?appVersion=20';
    }
}