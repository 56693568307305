import { Component, OnInit, Inject, Input } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'line-up',
  templateUrl: './line-up.component.html'
})
export class LineUpComponent implements OnInit {

  restuarntsItems;
  @Input() currentLanguage;

  constructor(@Inject(WINDOW) private window: Window) { }

  ngOnInit() {
    this.restuarntsItems =  [1,2,3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18,  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
  }

  openPopup(url) {
    let windowHeight = window.innerHeight;

    let screenWidth = screen.width;
    let windowWidth = window.innerWidth;

    let popupWidth = windowWidth - 100;
    let popupHeight = windowHeight - 100;
    let popupLeft = (screenWidth - popupWidth) / 2;

    this.window.open(url, "_blank", "scrollbars=yes,resizable=yes,top=50,left=" + popupLeft + ",width=" + popupWidth + ", height = " + popupHeight);
  }

  openFullScedule(number?) {
    if (this.currentLanguage === 'en') {
      return;
    }
    if(number) {
      this.openPopup("./assets/pdf/areas/"+number+".pdf?appVersion=20");
    } else {
      this.openPopup("./assets/pdf/luz.pdf");
    }
    
  }
  openFullResturantList() {

    if (this.currentLanguage === 'en') {
      this.openPopup("./assets/pdf/page_restaurants_english.pdf?appVersion=20");
    } else {
      this.openPopup("./assets/pdf/page_restaurants_hebrew.pdf?appVersion=20");
    }
    
  }

}
