import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'desktop-header',
  templateUrl: './desktop-header.component.html'
})
export class DesktopHeaderComponent implements OnInit {

  @Output() onLanguageChange = new EventEmitter();
  @Output() onSectionSelected = new EventEmitter();

  @Input() sections;
  @Input() languages;

  firstMenuItems;
  lastMenuItems;

  constructor() { }

  ngOnInit() {
    this.firstMenuItems = this.sections.slice(0, this.sections.length/2);
    this.lastMenuItems = this.sections.slice(this.sections.length / 2);
  }

  onMenuItemClicked(item) {
    this.onSectionSelected.emit(item.id);
  }

  changeLanguage(newLang) {
    this.onLanguageChange.emit(newLang);
  }

  goToSignup() {
    this.onSectionSelected.emit('signup');
  }



}
