import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component'
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component'
import { AboutComponent } from './components/about/about.component'
import { PartnerComponent } from './components/partner/partner.component'
import { TakePartComponent } from './components/take-part/take-part.component'
import { LineUpComponent } from './components/line-up/line-up.component'
import { SignupComponent } from './components/signup/signup.component'
import { MapComponent } from './components/map/map.component'

import { AgmCoreModule } from '@agm/core';

import { FormsModule } from '@angular/forms';

//import { NgxCaptchaModule } from 'ngx-captcha';
import { ReactiveFormsModule } from '@angular/forms';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/in18/', '.json?appVersion=20');
}

@NgModule({
  declarations: [
    AppComponent,
    MobileHeaderComponent,
    DesktopHeaderComponent,
    AboutComponent,
    PartnerComponent,
    TakePartComponent,
    LineUpComponent,
    SignupComponent,
    MapComponent
  ],
  imports: [
    CommonModule,
   // NgxCaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBSlp0q3gIwMb6ZnDBAefEYcjqgafQxNAs'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,


    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
  ],
  providers: [],
})
export class AppModule { }
