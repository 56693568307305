import { Component, OnInit, Input, Inject } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { WINDOW } from '@ng-toolkit/universal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { encode } from 'punycode';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html'
})


export class SignupComponent implements OnInit {

  @Input() currentLanguage;

  items: Observable<any[]>;
  firstName;
  lastName;
  email;
  phone;
  error;
  formSubmitted: boolean;
  sentSuccessfully: boolean;
  siteKey = '6LfDWKQUAAAAAF11NttdKFjkL5Y9U402TUUlFp8T';

  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  loading: boolean;

  aFormGroup: FormGroup;

  token;

  constructor(@Inject(WINDOW) private window: Window, private db: AngularFireDatabase, 
                      private formBuilder: FormBuilder,
                      private http: HttpClient) { }

  ngOnInit() {
    
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    // this.items = db.list('items').valueChanges();
   /////////// db.list('items').remove();
   // const itemsRef = db.list('items');
   // itemsRef.push({ name: "tviya22" });
    //itemsRef.remove('-La7TsltHlrQWmHv6g3u');

   // itemsRef.update('-La7TsltHlrQWmHv6g3u', { size: "cohen" });
  }

  onInputChange(form) {
    if(this.formSubmitted) {
      this.setError(form);
    }
  }

  setError(form) {
    this.error = "";
    if (!form.value.firstName && !form.value.lastName && !form.value.email) {
      this.error = "Please enter your name and your email";
      return;
    }
    if (!form.value.firstName && !form.value.lastName) {
      this.error = "Please enter your name";
      return;
    }

    if (!form.value.firstName) {
      this.error = "Please enter your first name";
      return;
    }
    if (!form.value.lastName) {
      this.error = "Please enter your last name";
      return;
    }
    if (!form.value.email) {
      this.error = "Please enter your email";
      return;
    }
    if (!this.emailPattern.test(form.value.email)) {
      this.error = "Please enter a valid email";
      return;
    }
   /* if (!this.token) {
      this.error = "Please click \"I'm not a robot\"";
    }*/
  }

  
  handleSuccess(event, form) {
    this.token = event;
    if (this.formSubmitted) {
      this.setError(form);
    }
  }

  onSubmit(form) {
    this.formSubmitted = true;

    this.setError(form);
    
    if(this.error) {
      return;
    }
    let user_data: any = {
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      email: form.value.email,
      date: new Date().getTime()
    };

    if (form.value.phone) {
      user_data.phone = form.value.phone;
    }
    
    
    this.loading = true;


    const appServerAuth = {
      identifier: "browser@vegan-friendly.com",
      password: "T6TH5h*y2oZ8",
    };



    this.http.post<any>('https://appadmin.vegan-friendly.co.uk/auth/local', appServerAuth).subscribe(data => { 
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.jwt}`
      }

      this.http.post<any>('https://appadmin.vegan-friendly.co.uk/user-contacts', { country: 'GB', subject: 'brighton market signup', details: 'phone number: ' + this.phone,   full_name: encodeURI(user_data.firstName + ' '  + user_data.lastName), email: user_data.email, phone: user_data.phone}, { headers }).subscribe(data => {

      (<any>this.window).dataLayer.push({ event: "form_submit_success" });

      this.sentSuccessfully = true;
      
      this.loading = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";

    }); })

  }
  

}
