import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'partner',
  templateUrl: './partner.component.html'
})
export class PartnerComponent implements OnInit {

  partnersTop = ['planted', 'new2'];
  partnersBottom = ['smaller1', 'smaller2'];

  constructor() { }

  ngOnInit() {

  }

}
